import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
};

export class Collateral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false, // Hides or shows the InfoWindow
      activeMarker: {}, // Shows the active marker upon click
      selectedPlace: {},
      activity: [],
    };
  }

  getTodaysDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = mm + "/" + dd + "/" + yyyy;
    return formattedToday;
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  async getGPSData() {
    let url = "https://caurisbackend.uc.r.appspot.com" + "/asaakGPSData";
    // let url = process.env.REACT_APP_API_DOMAIN + "/yofioGPSData";

    return fetch(url)
      .then((response) => response.json())

      .catch((error) => console.log(error));
  }

  async showGPSData() {
    let gpsData = await this.getGPSData();
    console.log("gpsData", gpsData);
    let rawdata = gpsData.assets;
    let mod_data = [];
    for (var i = 0; i < rawdata.length; i++) {
      if (rawdata[i].tracking) {
        mod_data.push(rawdata[i]);
      }
    }
    this.setState({ activity: mod_data });
  }
  componentDidMount() {
    this.showGPSData();
    // this.setState({ chainName: ChainService.getNetwork() });
  }
  render() {
    const listItems = this.state.activity.map((loc) => (
      <Marker
        onClick={this.onMarkerClick}
        name={"Last position:  " + this.getTodaysDate()}
        id={"Bike ID:  " + loc.id}
        key={"marker_" + loc.id}
        position={{
          lat: parseFloat(loc.tracking.lat),
          lng: parseFloat(loc.tracking.lng),
        }}
      />
    ));
    return (
      <div>
        <Map
          google={this.props.google}
          zoom={8}
          style={mapStyles}
          mapTypeId="satellite"
          initialCenter={{
            lat: 0.36944,
            lng: 32.59733,
          }}
        >
          {listItems}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}
          >
            <div>
              <h5>{this.state.selectedPlace.name}</h5>
              <h7>{this.state.selectedPlace.id}</h7>
              <br></br>
              {this.state.selectedPlace.speed &&
              this.state.selectedPlace.rawSpeed > 0 ? (
                <h7>
                  {" "}
                  Speed: {this.state.selectedPlace.rawSpeed.split(".0")[0]} kmh
                </h7>
              ) : (
                <div></div>
              )}
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDTfta7Rw_2d88kO_L8kTYSL_xw6gOtSng",
})(Collateral);
// import React, { Component } from "react";

// import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

// export class MapComponent extends Component {
//   render() {
//     return (
//       <div className="map-area">
//         <Map
//           google={this.props.google}
//           zoom={14}
//           center={{
//             lat: 0.55408,
//             lng: 32.55407,
//           }}
//         >
//           <Marker
//             key="marker_1"
//             position={{
//               lat: 0.36944,

//               lng: 32.59733,
//             }}
//           />
//         </Map>
//       </div>
//     );
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDTfta7Rw_2d88kO_L8kTYSL_xw6gOtSng",
// })(MapComponent);
