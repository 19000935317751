import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CardHeader } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Finacials({
  setParentActive,
  isFinancialsValid,
  setManagementAccounts,
  setAuditedFinancials,
  managementAccounts,
  auditedFinanicals,
  setMgmt1,
  setMgmt2,
  mgmt1,
  mgmt2,
  hasAuditedFinancials,
  setHasAuditedFinancials,
  mainAuditedFile,
  setMainAuditedFile,
  setBusinessPlan,
  businessPlan,
}) {
  const [showAudited, setShowAudited] = useState(hasAuditedFinancials);
  const [count, setCount] = useState(0);
  const [stageComplete, setStageComplete] = useState(false);

  // const [auditeddFinanicals, setAuditedFinancials] = useState("");
  // const [financialSummary, setFinancialSummary] = useState("");
  // const [loanPortfolio, setLoanPortfolio] = useState("");
  function onAuditedFinancialsChange(e) {
    let newAudit = auditedFinanicals;
    newAudit.push(e.target.files[0]);
    setAuditedFinancials(newAudit);
    setMainAuditedFile(e.target.files[0]);
    // this.setState({ fileData: e.target.files[0] });
  }

  function onBusinessPlan(e) {
    setBusinessPlan(e.target.files[0]);
    // this.setState({ fileData: e.target.files[0] });
  }

  function onManagementAccountsChange(e) {
    let newMgmt = managementAccounts;
    managementAccounts.push(e.target.files[0]);
    setManagementAccounts(newMgmt);
    isFinancialsValid();
    setStageComplete(true);

    if (e.target.name === "mgmt1") {
      setMgmt1(e.target.files[0]);
    } else if (e.target.name === "mgmt2") {
      setMgmt2(e.target.files[0]);
    }
  }

  function onAuditedSwitch(e) {
    setHasAuditedFinancials(e.target.checked);
    setShowAudited(e.target.checked);
  }

  return (
    <div>
      {" "}
      <Card className="text-left w-100 m-auto" border="light">
        <CardHeader>
          {" "}
          <div className="sectionHeader">Financials</div>
        </CardHeader>
        <Card.Body>
          <Form>
            <b>Business Plan (.xlsx)</b>
            <br></br>
            {businessPlan !== "" ? (
              <div>
                <b>File Selected: </b>
                {businessPlan.name}
                <button onClick={() => setBusinessPlan("")}> Edit</button>
                <p>&nbsp;</p>
              </div>
            ) : (
              <div>
                {" "}
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="file"
                    accept=".xlsx"
                    name="businessPlan"
                    onChange={onBusinessPlan}
                  />
                </Form.Group>
              </div>
            )}
            <b>Management accounts - 2023 (required) and 2022 (if available)</b>
            <br></br>
            FY 2023
            {mgmt1 !== "" ? (
              <div>
                <b>File Selected: </b>
                {mgmt1.name}
                <button onClick={() => setMgmt1("")}> Edit</button>
                <p>&nbsp;</p>
              </div>
            ) : (
              <div>
                {" "}
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="file"
                    accept=".xlsx"
                    name="mgmt1"
                    onChange={onManagementAccountsChange}
                  />
                </Form.Group>
              </div>
            )}
            FY 2022 (if available)
            {mgmt2 !== "" ? (
              <div>
                <b>File Selected: </b>
                {mgmt2.name}
                <button onClick={() => setMgmt2("")}> Edit</button>
              </div>
            ) : (
              <div>
                {" "}
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="file"
                    accept=".xlsx"
                    name="mgmt2"
                    onChange={onManagementAccountsChange}
                  />
                </Form.Group>
              </div>
            )}
          </Form>
          <p>&nbsp;</p>
          <Form>
            <b>Do you have audited financials? </b>
            <br></br>
            {/* Audited financials or management accounts (YTD + last 2 years): */}
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label=""
                checked={hasAuditedFinancials}
                onChange={onAuditedSwitch}
              />
            </Form.Group>
          </Form>
          {showAudited ? (
            <div>
              <Form>
                <b>Audited Financials (.pdf) -- (if available)</b>
                <br></br>

                {mainAuditedFile !== "" ? (
                  <div>
                    <b>File Selected: </b>
                    {mainAuditedFile.name}
                    <button onClick={() => setMainAuditedFile("")}>
                      {" "}
                      Edit
                    </button>
                    <p>&nbsp;</p>
                  </div>
                ) : (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".pdf"
                      onChange={onAuditedFinancialsChange}
                    />
                  </Form.Group>
                )}
              </Form>
              <div className="optionalUpload">
                <div className="optionalUploadItemBig">
                  {" "}
                  <Button onClick={() => setCount(count + 1)}>
                    Add Additional Files
                  </Button>
                </div>
              </div>
              <p>&nbsp;</p>
              {[...Array(count)].map((_, i) => (
                <div className="optionalUpload">
                  <div>
                    <b>Additional audited financial file (.pdf) </b>
                    <br></br>
                    <div className="optionalUploadItemBig">
                      <Form.Control
                        type="file"
                        accept=".pdf"
                        onChange={onAuditedFinancialsChange}
                      />{" "}
                    </div>

                    <div className="optionalUploadItem">
                      <Button
                        variant="danger"
                        onClick={() => setCount(count - 1)}
                      >
                        Remove
                      </Button>{" "}
                    </div>

                    <p>&nbsp;</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}

          {/* <Form>
            <b>Financial Summary (.xlsx)</b>
            <br></br>
            (Cauris Template):
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                accept=".xlsx"
                onChange={onFinancialSummaryChange}
              />
            </Form.Group>
          </Form> */}
          <div className="navButtons">
            <div className="backButton">
              <Button variant="secondary" onClick={() => setParentActive("1")}>
                Back
              </Button>
            </div>
            {isFinancialsValid() || (stageComplete && businessPlan !== "") ? (
              <div className="nextButton">
                <Button variant="success" onClick={() => setParentActive("3")}>
                  Next
                </Button>
              </div>
            ) : (
              <div className="nextButton">
                <Button
                  variant="secondary"
                  onClick={() => setParentActive("3")}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Finacials;
