import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import BarLineChartIcon from "@rsuite/icons/BarLineChart";
import PieChartIcon from "@rsuite/icons/PieChart";
function ProgressSection({
  setParentActive,
  activeSection,
  isFormValid,
  flow,
}) {
  //   const [active, setActive] = useState("1");

  function setSection(section) {
    // setActive(section);
    setParentActive(section);
  }

  return (
    <div>
      <Card border="light">
        <div className="statusCard">
          {isFormValid() ? (
            <div className="statusActiveValid">{activeSection}</div>
          ) : (
            <div className="statusActive">{activeSection}</div>
          )}
          {activeSection === "4" ? (
            <div className="statusInactive">/4</div>
          ) : (
            <div className="statusInactive">/3</div>
          )}
        </div>
      </Card>

      <Sidenav>
        <Sidenav.Body>
          {activeSection === "4" ? (
            <Nav activeKey={activeSection}>
              <Nav.Item
                onClick={() => setSection("1")}
                eventKey="1"
                icon={<FileUploadIcon />}
              >
                Additional Documents
              </Nav.Item>
            </Nav>
          ) : (
            <Nav activeKey={activeSection}>
              <Nav.Item
                onClick={() => setSection("1")}
                eventKey="1"
                icon={<GroupIcon />}
              >
                Company Details
              </Nav.Item>
              <Nav.Item
                onClick={() => setSection("2")}
                eventKey="2"
                icon={<PieChartIcon />}
              >
                Financials
              </Nav.Item>
              <Nav.Item
                onClick={() => setSection("3")}
                eventKey="3"
                icon={<BarLineChartIcon />}
              >
                {flow === "transactionalCapital"
                  ? "Transactional Capital"
                  : "Loan Portfolio"}
              </Nav.Item>
            </Nav>
          )}
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
}

export default ProgressSection;
