import "./App.css";

import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.css";
import city_scape from "./city_scape.jpg";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ProgressSection from "./ProgressSection";
import CompanyDetails from "./CompanyDetails";
import Finacials from "./Financials";
import ProgressBarView from "./ProgressBarView";
import LoanPortfolio from "./LoanPortfolio";
import { useParams } from "react-router-dom";
function StageTwo() {
  const queryParams = new URLSearchParams(window.location.search);
  const flowPassed = queryParams.get("flow");
  const [flow, setFlow] = useState(flowPassed);

  let applicantStored = localStorage.getItem("applicant");
  if (!applicantStored) {
    applicantStored = "";
  }
  let emailAddressStored = localStorage.getItem("emailAddress");
  if (!emailAddressStored) {
    emailAddressStored = "";
  }
  let websiteStored = localStorage.getItem("website");
  if (!websiteStored) {
    websiteStored = "";
  }
  let yearsFoundedStored = localStorage.getItem("yearsFounded");
  if (!yearsFoundedStored) {
    yearsFoundedStored = "";
  }
  let companyInfosStored = localStorage.getItem("companyInfo");
  if (!companyInfosStored) {
    companyInfosStored = "";
  }
  let businessModelStored = localStorage.getItem("businessModel");
  if (!businessModelStored) {
    businessModelStored = "";
  }

  const [emailAddress, setEmailAddress] = useState(emailAddressStored);
  const [companyName, setCompanyName] = useState(applicantStored);
  const [companyInfo, setCompanyInfo] = useState(companyInfosStored);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [website, setWebsite] = useState(websiteStored);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState(false);
  const [businessModel, setBusinessModel] = useState(businessModelStored);
  const [financialSummary, setFinancialSummary] = useState("");
  const [loanPortfolio, setLoanPortfolio] = useState("");
  const [companyPresentation, setCompanyPresentation] = useState("");
  const [yearsFounded, setYearsFounded] = useState(yearsFoundedStored);
  const [managementAccounts, setManagementAccounts] = useState([]);
  const [auditedFinanicals, setAuditedFinancials] = useState([]);
  const [mgmt1, setMgmt1] = useState("");
  const [mgmt2, setMgmt2] = useState("");
  const [businessPlan, setBusinessPlan] = useState("");

  const [hasAuditedFinancials, setHasAuditedFinancials] = useState("");

  const [mainAuditedFile, setMainAuditedFile] = useState("");

  const [activeSection, setActiveSection] = useState("1");
  const [stageTwoComplete, setStageTwoComplete] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // setStageTwoComplete(true);
  function updateActiveSection(section) {
    console.log(section);
    setActiveSection(section);
  }

  function isFinancialsValid() {
    var is_valid = true;

    if (managementAccounts.length === 0) {
      is_valid = false;
    }
    if (businessPlan === "") {
      is_valid = false;
    }

    saveState();

    return is_valid;
  }

  function isLoanPortfolioValid() {
    var is_valid = true;
    if (loanPortfolio === "") {
      is_valid = false;
    }
    saveState();

    return is_valid;
  }

  function isCompanyDetailsValid() {
    var is_valid = true;
    if (businessModel === "") {
      is_valid = false;
    }
    if (emailAddress === "") {
      is_valid = false;
    }
    if (companyName === "") {
      is_valid = false;
    }
    if (companyInfo === "") {
      is_valid = false;
    }
    console.log("companyPresentation", companyPresentation);
    if (companyPresentation === "") {
      is_valid = false;
    }
    if (website === "") {
      is_valid = false;
    }
    if (yearsFounded === "") {
      is_valid = false;
    }
    saveState();
    return is_valid;
  }

  function saveState() {
    localStorage.setItem("applicant", companyName);
    localStorage.setItem("emailAddress", emailAddress);
    localStorage.setItem("website", website);
    localStorage.setItem("yearsFounded", yearsFounded);
    localStorage.setItem("businessModel", businessModel);
    localStorage.setItem("companyInfo", companyInfo);
  }

  function isFormValid() {
    if (
      isCompanyDetailsValid() &&
      isFinancialsValid() &&
      isLoanPortfolioValid()
    ) {
      return true;
    } else {
      return false;
    }
  }

  function submitForm() {
    const formData = new FormData();
    formData.append("applicant", companyName);
    formData.append("emailAddress", emailAddress);
    formData.append("website", website);
    formData.append("yearsFounded", yearsFounded);
    formData.append("companyInfo", companyInfo);
    formData.append("businessModel", businessModel);
    formData.append("companyPresentation", companyPresentation);

    formData.append("financialSummary", financialSummary);
    formData.append("loanPortfolio", loanPortfolio);
    formData.append("businessPlan", businessPlan);
    formData.append("flow", flow);

    for (var i = 0; i < managementAccounts.length; i++) {
      formData.append(
        "managementAccounts_" + i.toString(),
        managementAccounts[i]
      );
    }

    for (var z = 0; z < auditedFinanicals.length; z++) {
      console.log("AUDIT NAME", "auditedFinancials_" + z.toString());
      formData.append(
        "auditedFinancials_" + z.toString(),
        auditedFinanicals[z]
      );
    }

    setIsLoading(true);
    let url = "https://caurisbackend.uc.r.appspot.com/submitApplication";
    // let url = "http://localhost:8080/submitApplication";
    axios.post(url, formData, {}).then((res) => {
      setSubmissionId(res["data"]["id"]);

      setIsLoading(false);
      setModalMessage(
        "Successfully submitted. A confirmation email has been sent to " +
          emailAddress +
          " Your application id is " +
          res["data"]["id"]
      );
      handleShow();
    });
  }
  return (
    <div>
      <div class="bg">
        <p></p>
        <Modal show={isLoading} onHide={handleClose}>
          <Modal.Header closeButton>Submitting Request</Modal.Header>
          <Modal.Body>
            {" "}
            <div class="spinner">
              <Spinner animation="border" variant="success" />
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Error</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="header m-auto">
          <img src={city_scape} alt="" />{" "}
          <Card.Body>
            Cauris’s Graduation Program offers an innovative credit product
            specifically designed to support promising African companies that
            are often overlooked by credit providers because they are considered
            too early for credit facilities. The program provides funding and
            capacity building intended to support the early-stage growth of
            these companies while also preparing them for larger-scale
            facilities in the future. Companies that participate in the
            Graduation Program will be assessed for compatibility with Cauris’s
            larger-scale growth facilities.
          </Card.Body>
        </Card>
        <div className="parent text-left header m-auto">
          <ProgressBarView
            activeSection={activeSection}
            isFormValid={isFormValid}
            stageTwoComplete={stageTwoComplete}
          />
        </div>
        <div className="parent text-left header m-auto">
          <div className="navSection">
            <ProgressSection
              setParentActive={updateActiveSection}
              activeSection={activeSection}
              isFormValid={isFormValid}
              flow={flow}
            />
          </div>
          <div className="formContent">
            {activeSection === "1" ? (
              <CompanyDetails
                setParentActive={updateActiveSection}
                setCompanyName={setCompanyName}
                setWebsite={setWebsite}
                setEmailAddress={setEmailAddress}
                setYearsFounded={setYearsFounded}
                setCompanyInfo={setCompanyInfo}
                setCompanyPresentation={setCompanyPresentation}
                setBusinessModel={setBusinessModel}
                isCompanyDetailsValid={isCompanyDetailsValid}
                website={website}
                companyName={companyName}
                emailAddress={emailAddress}
                yearsFounded={yearsFounded}
                companyInfo={companyInfo}
                companyPresentation={companyPresentation}
                businessModel={businessModel}
              />
            ) : (
              ""
            )}
            {activeSection === "2" ? (
              <Finacials
                setAuditedFinancials={setAuditedFinancials}
                setFinancialSummary={setFinancialSummary}
                setParentActive={updateActiveSection}
                isFinancialsValid={isFinancialsValid}
                setManagementAccounts={setManagementAccounts}
                managementAccounts={managementAccounts}
                auditedFinanicals={auditedFinanicals}
                mgmt1={mgmt1}
                mgmt2={mgmt2}
                setMgmt1={setMgmt1}
                setMgmt2={setMgmt2}
                hasAuditedFinancials={hasAuditedFinancials}
                setHasAuditedFinancials={setHasAuditedFinancials}
                mainAuditedFile={mainAuditedFile}
                setMainAuditedFile={setMainAuditedFile}
                setBusinessPlan={setBusinessPlan}
                businessPlan={businessPlan}
              />
            ) : (
              ""
            )}
            {activeSection === "3" ? (
              <LoanPortfolio
                submitForm={submitForm}
                setParentActive={updateActiveSection}
                setLoanPortfolio={setLoanPortfolio}
                isFormValid={isFormValid}
                isCompanyDetailsValid={isCompanyDetailsValid}
                isFinancialsValid={isFinancialsValid}
                isLoanPortfolioValid={isLoanPortfolioValid}
                loanPortfolio={loanPortfolio}
                flow={flow}
              />
            ) : (
              ""
            )}
            Need help? We would be happy to assist:{" "}
            <a href="mailto:gradfund@caurisfinance.com" alt="">
              gradfund@caurisfinance.com
            </a>
            <p>&nbsp;</p>
            <div class="notice">
              Please note that all data requests and submissions are for
              discussion purposes only, are non-binding, and do not constitute a
              commitment to lend, as Cauris must complete an underwriting of all
              submissions and receive necessary internal and external approvals,
              as may be applicable.
            </div>
          </div>
        </div>

        <Card className="text-left header m-auto">
          <Card.Footer>
            <div class="footer"> © 2024 CAURIS, INC. ALL RIGHTS RESERVED.</div>
          </Card.Footer>
        </Card>
        <p>&nbsp;</p>
      </div>{" "}
    </div>
  );
}

export default StageTwo;
