import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CardFooter } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CompanyDetails({
  setParentActive,
  setCompanyName,
  setWebsite,
  setEmailAddress,
  setYearsFounded,
  setCompanyInfo,
  setCompanyPresentation,
  setBusinessModel,
  isCompanyDetailsValid,
  website,
  companyName,
  emailAddress,
  yearsFounded,
  companyInfo,
  companyPresentation,
  businessModel,
}) {
  function onCompanyPresentationChange(e) {
    setCompanyPresentation(e.target.files[0]);
  }
  function testDate(e) {
    const month = e.getUTCMonth() + 1; // months from 1-12
    const day = e.getUTCDate();
    const year = e.getUTCFullYear();
    setYearsFounded(month + " / " + year);
  }
  return (
    <div>
      {" "}
      <Card className="text-left" border="light">
        <Card.Header>
          <div className="sectionHeader">Company Details</div>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Company Name</b>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Enter your company name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Website</b>
              </Form.Label>
              <Form.Control
                size="sm"
                placeholder="https://"
                type="website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Email address (main contact for applicant)</b>
              </Form.Label>
              <Form.Control
                size="sm"
                type="email"
                placeholder="name@example.com"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>When was the company founded? &nbsp;</b>
              </Form.Label>
              {/* <Form.Control
                size="sm"
                type="date"
                inputType="month"
                placeholder="How many years ago were you founded?"
                value={yearsFounded}
                showMonthYearPicker
                onChange={(e) => setYearsFounded(e.target.value)}
              /> */}

              <DatePicker
                value={yearsFounded}
                onChange={(e) => testDate(e)}
                showMonthYearPicker
                showIcon
                dateFormat="MM/yyyy"
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <b>Tell us about your company</b>
              </Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                rows={2}
                value={companyInfo}
                onChange={(e) => setCompanyInfo(e.target.value)}
              />
              <br></br>

              <Form.Label>
                <b>Primary Business Model</b>
              </Form.Label>
              <Form.Select
                size="sm"
                onChange={(e) => setBusinessModel(e.target.value)}
              >
                <option value="Mobility Financing">
                  Mobility Financing (including electric vehicles)
                </option>
                <option value="SME Financing">SME Financing</option>
                <option value="Remittances">Remittances</option>

                <option value="Trade Financing">Trade Financing</option>
                <option value="Other">Other</option>

                {/* <option value="Other">Other</option> */}
              </Form.Select>
              {/* <Form.Control
                type="text"
                rows={1}
                placeholder="Other"
                onChange={(e) => setBusinessModel(e.target.value)}
              /> */}
            </Form.Group>
          </Form>
          <Form>
            <b>Company Presentation (.pdf)</b>
            <br></br>
            {companyPresentation === "" ? (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                  type="file"
                  accept=".pdf"
                  onChange={onCompanyPresentationChange}
                />
              </Form.Group>
            ) : (
              <div>
                <b>File Selected: </b>
                {companyPresentation.name}
                <button onClick={() => setCompanyPresentation("")}>
                  {" "}
                  Edit
                </button>
              </div>
            )}
          </Form>
          <div className="navButtons">
            {isCompanyDetailsValid() ? (
              <div className="nextButton">
                <Button variant="success" onClick={() => setParentActive("2")}>
                  Next
                </Button>
              </div>
            ) : (
              <div className="nextButton">
                <Button
                  variant="secondary"
                  onClick={() => setParentActive("2")}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>{" "}
    </div>
  );
}

export default CompanyDetails;
