import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";

function ProgressBarView({ activeSection, isFormValid, stageTwoComplete }) {
  return (
    <div>
      {isFormValid() || stageTwoComplete ? (
        <ProgressBar variant="success" now={parseInt(activeSection) * 34} />
      ) : (
        <ProgressBar now={parseInt(activeSection) * 34} />
      )}
    </div>
  );
}

export default ProgressBarView;
