import "./App.css";

import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.css";
import city_scape from "./city_scape.jpg";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ProgressBarView from "./ProgressBarView";
import Carousel from "react-bootstrap/Carousel";
import ImageFiller from "react-image-filler";
import Nav from "react-bootstrap/Nav";

function StageOne() {
  const [index, setIndex] = useState(0);

  const [answersCorrect, setAnswersCorrect] = useState(0);
  const [answersIncorrect, setAnswersIncorrect] = useState(0);

  function onClickYes() {
    const new_index = index + 1;
    if (new_index < questions.length + 1) {
      setAnswersCorrect(answersCorrect + 1);
      setIndex(new_index);
    }
  }

  function onClickNo() {
    const new_index = index + 1;
    if (new_index < questions.length + 1) {
      setAnswersIncorrect(answersIncorrect + 1);
      setIndex(new_index);
    }
  }

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const questions = [
    "Do you operate in Africa?",
    "Do you have at least 6 months of cash runway?",
    "Do you need debt capital to finance your lending loan book, float for remittances or payments, or for another critical business model?",
    "Does your product(s) drive financial inclusion and/or the financial empowerment and agency of traditionally marginalized segments?",
    "Do you have a loan management system (LMS) currently deployed (proprietary or 3rd party)?",
    "Is your outstanding loan book portfolio portfolio $500k or greater? Or have you processed more than $5M in remittances?",
  ];

  let questionList = [];

  questions.forEach((question, index) => {
    questionList.push(
      <Carousel.Item>
        <div className="head-text">
          <ImageFiller
            width={900}
            height={500}
            slide="false"
            text={" "}
            background={"#6599CA"}
          />
          <div class="text-on-image">
            <h2> {question} </h2>
            <p>&nbsp;</p>
            <div className="feedbackButtons">
              <div className="feedbackButtonRight">
                {" "}
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => onClickYes()}
                >
                  Yes
                </Button>
              </div>{" "}
              <div className="feedbackButton">
                {" "}
                <Button
                  width={500}
                  variant="danger"
                  size="lg"
                  onClick={() => onClickNo()}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  });

  questionList.push(
    <Carousel.Item>
      <div className="head-text">
        <ImageFiller
          width={900}
          height={500}
          slide="false"
          text={" "}
          background={"#6599CA"}
        />
        <div class="text-on-image">
          {answersIncorrect === 0 ? (
            <div>
              <h2>
                {" "}
                {
                  "Congratulations! Looks like you may be a candidate for the Graduation Fund. Click 'Proceed' to advance to the next section of the application."
                }{" "}
              </h2>
              <p>&nbsp;</p>
              <div className="feedbackButtons">
                <div className="feedbackButtonRight">
                  {" "}
                  <Nav.Link href="/stageTwo">
                    <Button variant="primary" size="lg">
                      Proceed
                    </Button>
                  </Nav.Link>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2>
                {" "}
                {
                  "Looks like you're not quite ready. In order to advance to the next stage, all of the prerequisites must be met. Come back when you're ready!"
                }{" "}
              </h2>
            </div>
          )}
        </div>
      </div>
    </Carousel.Item>
  );

  return (
    <div>
      <div class="bg">
        <p></p>

        <Card className="header m-auto">
          <img src={city_scape} alt="" />{" "}
          <Card.Body>
            Cauris’s Graduation Program offers an innovative credit product
            specifically designed to support promising African companies that
            are often overlooked by credit providers because they are considered
            too early for credit facilities. The program provides funding and
            capacity building intended to support the early-stage growth of
            these companies while also preparing them for larger-scale
            facilities in the future. Companies that participate in the
            Graduation Program will be assessed for compatibility with Cauris’s
            larger-scale growth facilities.
          </Card.Body>
        </Card>
        <div>
          <div className="parent text-left header m-auto questionsBackground">
            {" "}
            <Carousel
              controls={false}
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              wrap={false}
            >
              {questionList}
            </Carousel>
          </div>
        </div>
        <Card className="text-left header m-auto">
          <Card.Footer>
            <div class="footer"> © 2024 CAURIS, INC. ALL RIGHTS RESERVED.</div>
          </Card.Footer>
        </Card>
        <p>&nbsp;</p>
      </div>{" "}
    </div>
  );
}

export default StageOne;
