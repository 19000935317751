import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CardFooter } from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { isValid } from "rsuite/esm/utils/dateUtils";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

function AdditionalDocsPartial({ applicantId }) {
  const [show, setShow] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const [incorporation, setIncorporation] = useState("");
  const [orgChart, setOrgChart] = useState("");
  const [consolidatedFinancials, setConsolidatedFinancials] = useState("");
  const [internalOrgChart, setInternalOrgChart] = useState("");
  const [fxPolicy, setFXPolicy] = useState("");
  const [operatingMarkets, setOperatingMarkets] = useState("");
  const [supportingDocs, setSupoortingDocs] = useState("");
  const [risks, setRisks] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  function onOrgChart(e) {
    setOrgChart(e.target.files[0]);
  }

  function onConsolidatedFinancials(e) {
    setConsolidatedFinancials(e.target.files[0]);
  }

  function onIntoernalOrgChart(e) {
    setInternalOrgChart(e.target.files[0]);
  }

  // function onSupportingDocs(e) {
  //   setSupoortingDocs(e.target.files[0]);
  // }

  function onFxManagementPolicy(e) {
    setFXPolicy(e.target.files[0]);
  }

  function onOperatingMarkets(e) {
    setOperatingMarkets(e.target.files[0]);
  }

  function isFormValid() {
    let is_valid = true;
    // if (emailAddress === "") {
    //   is_valid = false;
    // }
    // if (incorporation === "") {
    //   is_valid = false;
    // }
    if (orgChart === "") {
      is_valid = false;
    }
    // if (consolidatedFinancials === "") {
    //   is_valid = false;
    // }
    if (internalOrgChart === "") {
      is_valid = false;
    }
    if (fxPolicy === "") {
      is_valid = false;
    }
    if (operatingMarkets === "") {
      is_valid = false;
    }
    // if (supportingDocs === "") {
    //   is_valid = false;
    // }
    // if (risks === "") {
    //   is_valid = false;
    // }
    return is_valid;
  }

  function submitForm() {
    const formData = new FormData();
    formData.append("applicantId", applicantId);
    // formData.append("email", emailAddress);
    // formData.append("incorporation", incorporation);
    formData.append("orgChart", orgChart);
    if (consolidatedFinancials !== "") {
      formData.append("consolidatedFinancials", consolidatedFinancials);
    }
    formData.append("fxPolicy", fxPolicy);
    formData.append("operatingMarkets", operatingMarkets);
    // formData.append("supportingDocs", supportingDocs);
    // formData.append("risks", risks);
    formData.append("internalOrgChart", internalOrgChart);

    setIsLoading(true);
    let url = "https://caurisbackend.uc.r.appspot.com/updateApplication";

    // let url = "http://localhost:8080/updateApplication";
    axios.post(url, formData, {}).then((res) => {
      setIsLoading(false);
      setModalMessage(
        "Additional documents successfully submitted. A confirmation email has been sent to " +
          emailAddress +
          " Your application id is " +
          res["data"]["id"]
      );
      handleShow();
    });
  }

  return (
    <div>
      {" "}
      <Modal show={isLoading} onHide={handleClose}>
        <Modal.Header closeButton>Submitting Request</Modal.Header>
        <Modal.Body>
          {" "}
          <div class="spinner">
            <Spinner animation="border" variant="success" />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Error</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="text-left" border="light">
        <Card.Header>
          <div className="sectionHeader">Additional Documents</div>
        </Card.Header>
        <Card.Body>
          {/* <form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Email Address</b>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Form.Group>
          </form> */}
          {/* <form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Where was your company incorporated?</b>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Location"
                value={incorporation}
                onChange={(e) => setIncorporation(e.target.value)}
              />
            </Form.Group>
          </form> */}
          <Form>
            <b>
              Can you provide a corporate org chart detailing holdco and
              operating companies jurisdictions? (.pdf)
            </b>
            <br></br>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" accept=".pdf" onChange={onOrgChart} />
            </Form.Group>
          </Form>
          <Form>
            <div className="optionalRow">
              <b>
                The financials provided are for WeWire Ghana Limited (in Ghs).
                Can you provide full consolidated financials assuming there are
                other entities?(.pdf)
              </b>
              &nbsp;
              <div className="optional"> (Optional)</div>
            </div>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={onConsolidatedFinancials}
              />
            </Form.Group>
          </Form>{" "}
          <Form>
            <b>
              Can you provide an internal org chart detailing the different
              roles you have within the company? Interested in knowing the
              structure of the team around AML and FX operations.(.pdf)
            </b>
            <br></br>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={onIntoernalOrgChart}
              />
            </Form.Group>
          </Form>
          <form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>Can you upload your FX risk management policy? (.pdf)</b>
              </Form.Label>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                  type="file"
                  accept=".pdf"
                  onChange={onFxManagementPolicy}
                />
              </Form.Group>
              {/* <Form.Control
                size="sm"
                type="text"
                placeholder=""
                value={fxPolicy}
                onChange={(e) => setFXPolicy(e.target.value)}
              /> */}
            </Form.Group>
          </form>
          <form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>
                  Can you provide documentation on the markets in which you send
                  and receive funds? How do you comply with the regulatory
                  requirements applicable in each market?{" "}
                </b>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={onOperatingMarkets}
              />
              {/* <Form.Control
                size="sm"
                type="text"
                placeholder=""
                value={operatingMarkets}
                onChange={(e) => setOperatingMarkets(e.target.value)}
              /> */}
            </Form.Group>
          </form>
          {/* <Form>
            <b>Please share the supporting documents.(.pdf)</b>
            <br></br>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={onSupportingDocs}
              />
            </Form.Group>
          </Form> */}
          {/* <form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>
                  What risks does the move from the CBN against Binance
                  highlight for your activity? How do you edge these risks?
                </b>
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder=""
                value={risks}
                onChange={(e) => setRisks(e.target.value)}
              />
            </Form.Group>
          </form> */}
          <div className="navButtons">
            {isFormValid() ? (
              <div className="nextButton">
                <Button variant="success" onClick={() => submitForm()}>
                  Submit
                </Button>
              </div>
            ) : (
              <div className="nextButton">
                <Button variant="secondary">Submit</Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>{" "}
    </div>
  );
}

export default AdditionalDocsPartial;
