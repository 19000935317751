import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import StageOne from "./StageOne";
import StageTwo from "./StageTwo";
import AdditionalDocs from "./AdditionalDocs";
import AsaakCollateral from "./AsaakCollateral";
import NumidaCollateral from "./NumidaCollateral";
import YofioCollateral from "./YofioCollateral";

import { createHashHistory } from "history";

export const history = createHashHistory();
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <StageTwo />
        </Route>
        <Route path="/preview">
          <StageOne />
        </Route>
        <Route path="/stageTwo">
          <StageTwo />
        </Route>
        <Route path="/additionalDocs">
          <AdditionalDocs />
        </Route>
        <Route path="/asaak_collateral">
          <AsaakCollateral />
        </Route>
        <Route path="/yofio_collateral">
          <YofioCollateral />
        </Route>{" "}
        <Route path="/numida_collateral">
          <NumidaCollateral />
        </Route>
        {/* <Route path="/dashboard">
          <Dashboard />
        </Route> */}
      </Switch>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
