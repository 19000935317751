// Where is the company incorporated? (Q)

// Can you provide a corporate org chart detailing holdco and operating companies jurisdictions? (PDF)

// The financials provided are for WeWire Ghana Limited (in Ghs). Can you provide full consolidated financials assuming there are other entities.(PDF)

// Can you provide an internal org chart detailing the different roles you have within the company? Interested in knowing the structure of the team around AML and FX operations (PDF)

// Can you describe your FX risk management policy?  (TEXT)

// In what markets do you do you send and receive funds? How do you comply with the regulatory requirements applicable in each market? (TEXT)

// Please share the supporting documents.(PDF)

// What risks does the move from the CBN against Binance highlight for your activity? How do you edge these risks? (TEXT)

import "./App.css";

import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.css";
import city_scape from "./city_scape.jpg";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ProgressSection from "./ProgressSection";
import CompanyDetails from "./CompanyDetails";
import Finacials from "./Financials";
import ProgressBarView from "./ProgressBarView";
import AdditionalDocsPartial from "./AdditionalDocsPartial";
import { useParams } from "react-router-dom";

function AdditionalDocs() {
  const queryParams = new URLSearchParams(window.location.search);
  const applicant_id = queryParams.get("applicant_id");
  const [applicantId, setApplicantId] = useState(applicant_id);
  const [activeSection, setActiveSection] = useState("4");
  console.log("applicantId", applicantId);
  function updateActiveSection(section) {
    console.log(section);
    setActiveSection(section);
  }

  function isFormValid() {
    return false;
  }

  return (
    <div>
      <div class="bg">
        <Card className="header m-auto">
          <img src={city_scape} alt="" />{" "}
          <Card.Body>
            Cauris’s Graduation Program offers an innovative credit product
            specifically designed to support promising African companies that
            are often overlooked by credit providers because they are considered
            too early for credit facilities. The program provides funding and
            capacity building intended to support the early-stage growth of
            these companies while also preparing them for larger-scale
            facilities in the future. Companies that participate in the
            Graduation Program will be assessed for compatibility with Cauris’s
            larger-scale growth facilities.
          </Card.Body>
        </Card>
        <div className="parent text-left header m-auto">
          <div className="navSection">
            <ProgressSection
              setParentActive={updateActiveSection}
              activeSection={activeSection}
              isFormValid={isFormValid}
              flow={""}
            />
          </div>
          <div className="formContent">
            <AdditionalDocsPartial applicantId={applicantId} />
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default AdditionalDocs;
