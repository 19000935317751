import "rsuite/dist/rsuite.min.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CardHeader } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function LoanPortfolio({
  submitForm,
  setParentActive,
  setLoanPortfolio,
  isFormValid,
  isCompanyDetailsValid,
  isFinancialsValid,
  isLoanPortfolioValid,
  loanPortfolio,
  flow,
}) {
  function onLoanPortfolioChange(e) {
    setLoanPortfolio(e.target.files[0]);
    // this.setState({ fileData: e.target.files[0] });
  }

  return (
    <div>
      {" "}
      <Card className="text-left w-100 m-auto" border="light">
        <CardHeader>
          {" "}
          <div className="sectionHeader">
            {flow === "transactionalCapital"
              ? "Transactional Capital"
              : "Loan Portfolio"}
          </div>
        </CardHeader>
        <Card.Body>
          <Form>
            {flow === "transactionalCapital" ? (
              <div>
                <b> Transaction Diagram (.pdf)</b>
                <br></br>
                provide a diagram laying out the transaction flow and specific
                step that needs financing, as well as the involved counterparts:{" "}
                <p>&nbsp;</p>
                {loanPortfolio === "" ? (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".pdf"
                      onChange={onLoanPortfolioChange}
                    />
                  </Form.Group>
                ) : (
                  <div>
                    <b>File Selected: </b>
                    {loanPortfolio.name}
                    <button onClick={() => setLoanPortfolio("")}> Edit</button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <b> Historical Loan Portfolio Summary (.xlsx)</b>
                <br></br>
                Please use this template when providing your loan portfolio
                summary:{" "}
                <a
                  href="https://storage.googleapis.com/cauris_deep_dive/Cauris%20Finance_Portfolio%20Summary_Template.xlsx"
                  target="_link"
                >
                  Link
                </a>
                <p>&nbsp;</p>
                {loanPortfolio === "" ? (
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      type="file"
                      accept=".xlsx"
                      onChange={onLoanPortfolioChange}
                    />
                  </Form.Group>
                ) : (
                  <div>
                    <b>File Selected: </b>
                    {loanPortfolio.name}
                    <button onClick={() => setLoanPortfolio("")}> Edit</button>
                  </div>
                )}
              </div>
            )}
          </Form>
          {!isFormValid() ? (
            <div>
              {" "}
              <div className="almostDone"> Almost Done</div>
              <div className="instruction">
                <b>
                  This information generally provides sufficient data for Cauris
                  to offer an initial term sheet for discussion.
                </b>
                <p>&nbsp;</p>
                Please go back and check the following sections:
              </div>
              <p>&nbsp;</p>
              {!isCompanyDetailsValid() ? (
                <Button
                  className="reviewButton"
                  variant="outline-danger"
                  onClick={() => setParentActive("1")}
                >
                  Company Details
                </Button>
              ) : (
                <div></div>
              )}
              <p>&nbsp;</p>
              {!isFinancialsValid() ? (
                <Button
                  className="reviewButton"
                  variant="outline-danger"
                  onClick={() => setParentActive("2")}
                >
                  Financials
                </Button>
              ) : (
                <div></div>
              )}
              <p>&nbsp;</p>
              {!isLoanPortfolioValid() ? (
                <Button
                  className="reviewButton"
                  variant="outline-danger"
                  onClick={() => setParentActive("3")}
                >
                  {flow === "transactionalCapital"
                    ? "Transactional Capital"
                    : "Loan Portfolio"}{" "}
                </Button>
              ) : (
                <div></div>
              )}
              <p>&nbsp;</p>
            </div>
          ) : (
            <div></div>
          )}

          <div className="navButtons">
            <div className="backButton">
              <Button variant="secondary" onClick={() => setParentActive("2")}>
                Back
              </Button>
            </div>
            {isFormValid() ? (
              <div className="nextButton">
                <Button variant="success" onClick={() => submitForm()}>
                  Submit Your Application
                </Button>
              </div>
            ) : (
              <div className="nextButton">
                <Button variant="secondary">Submit Your Application</Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default LoanPortfolio;
